import gql from 'graphql-tag';

export const CAMPAIGNS = gql`
    query AllCampaignsQuery($pageSize: PageSize) {
        campaigns: campaigns(pageSize: $pageSize) {
            id,
            validFrom,
            validTo,
            state,
            priorityOrder,
            localizations {
                title,
                description
            }
            travelTips {
                id,
                state,
                topDate,
                titlePicture {
                    fileName
                },
                theme,
                dayCount,
                localizations {
                    title
                }
                itineraryDays {
                    itineraryItems {
                        countries {
                            nameCzech
                            nameEnglish
                        }
                        transferTypeValues {
                            transferType
                        }
                    }
                }
            },
        }
    }`;

export const CAMPAIGNS_SHORT_LIST = gql`
    query AllCampaignsQuery {
        campaigns: campaigns {
            id,
            validFrom,
            validTo,
            state,
            author {
                userName
            },
            localizations {
                title
            }
        }
    }`;

export const CAMPAIGNS_TO_GET_COUNTRIES = gql`
    query AllCampaignsAdminQuery {
        campaigns{
            travelTips {
                itineraryDays {
                    itineraryItems {
                        countries {
                            code
                            nameCzech
                        }
                    }
                }
            },
        }
    }`;
export const CAMPAIGNS_SHORT_LIST_ADMIN = gql`
    query AllCampaignsAdminQuery {
        campaigns: campaignsAdmin {
            id,
            priorityOrder,
            localizations {
                language
                title
                description
            }
            travelTips {
                id,
                itineraryDays {
                    id,
                    itineraryItems {
                        id
                        countries {
                            code
                            nameCzech
                            nameEnglish
                        }
                    }
                }
            },
        }
    }`;

export const CAMPAIGN_TITLES_ADMIN = gql`
    query AllCampaignTitlesAdminQuery {
        campaigns: campaignsAdmin {
            id
            localizations {
                language
                title
            }
        }
    }`;

export const CAMPAIGNS_SHORT_LIST_ADMIN_FILTERED = gql`
    query FilteredCampaignsAdminQuery ($filter: CampaignsAdminFilter, $pageSize: PageSize) {
        filteredCampaigns: campaignsAdmin (filter: $filter, pageSize: $pageSize) {
            id,
            validFrom,
            validTo,
            state,
            priorityOrder,
            originalLanguage,
            localizations {
                language,
                title,
                description
            }
            travelTips {
                id,
                state,
                localizations {
                    title
                }
                itineraryDays {
                    itineraryItems {
                        countries {
                            code
                            nameCzech
                            nameEnglish
                        }
                    }
                }
            },
            author {
                id
                userName
                avatarFileName
            },
            __typename
        }
    }`;

export const CAMPAIGN_BY_ID_ADMIN = gql`
    query GetCampaignByIdAdminQuery($campaignId: Int!) {
        campaign: campaignAdmin(campaignId: $campaignId) {
            id, 
            validFrom, 
            validTo, 
            state,
            localizations {
                language
                title,
                description,
                published
            }
            originalLanguage
            travelTips {
                id
                titlePicture {
                    fileName
                },
                topDate,
                state,
                purchasable,
                campaign {
                    localizations {
                        title
                    }
                },
                originalLanguage
                localizations {
                    language
                    title,
                    shortInfo
                }
                itineraryDays {
                    itineraryItems {
                        difficulty,
                        transferTypeValues {
                            transferType,
                        },
                        countries {
                            id,
                            nameCzech,
                            nameEnglish,
                        }
                    }
                },
                author {
                    userName
                    avatarFileName
                },
            }
        }
    }`;

    export const ACTIVE_CAMPAIGNS = gql`
    query FilteredCampaignsAdminQuery ($filter: CampaignsAdminFilter) {
        filteredCampaigns: campaignsAdmin (filter: $filter) {
            id
        }
    }`;

