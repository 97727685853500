<template>
    <div class="bg-white-alt">
        <NavBarNew :background="true"/>
        <section class="glasses-block" @mousemove="(event) => throttle(event, 50)" @mouseenter="bgHover=true;" @mouseleave="bgHover=false" v-bind:style="{ 'background-image': 'url(' + imgUrl + ')' }">
            <!-- TODO -->
            <!-- <img v-if="bgHover" src="../assets/svg/clipedBigger.svg" class="cursor absolute-animated"> -->
            <img class="absolute-animated" src="../assets/svg/clipedBigger.svg" alt="glasses">
        </section>
        <div id='header-container'>
          <div class='container'>
            <div id="main-jumbo" class="jumbotron jumbotron-fluid">
                <div class="container">
                    <h1 class="display-4 text-left" style="max-width: 800px">
                      {{ $t("public.homePage.homepageTagline") }}
                    </h1>
                </div>
            </div>
            <div class="container text-left">
              <TrekioButton class="enter-button" primary @click="scrollToFavoriteTravelTips">{{ $t('buttons.hopOn') }}</TrekioButton>
            </div>
          </div>
        </div>
        <div id='main-container'>
          <div class="container">
            <!-- <div id="pros-wrapper"> -->
            <div id="pros-wrapper-carousel">
              <hooper class="pros-hooper" :settings="hooperSettings" :wheel-control="false" :transition="500" :autoPlay="false" :playSpeed="8000" :itemsToShow="5.15" :centerMode="true" pagination="no" :infiniteScroll="false" :perPage=2 :speed=300 :loop="true" :minSwipeDistance=10 :scrollPerPage="false" :paginationEnabled="false">
                <slide class="pros-slide">
                  <div class="pros-div">
                    <div class="pros-div-wrapper">
                      <img class="position-absolute icon" src="../assets/svg/homepage-pros-1.svg">
                      <div class="slogan" style="margin-top: 10px">{{ $t("public.homePage.experiencesTitle") }}</div>
                      <p>{{ $t("public.homePage.experiencesText") }}</p>
                      <p class="subtitle">{{ $t("public.homePage.experiencesSubTitle") }}</p>
                  </div>
                </div>
              </slide>
              <slide class="pros-slide">
                <div class="pros-div">
                    <img class="position-absolute icon" src="../assets/svg/homepage-pros-2.svg">
                    <div class="slogan" style="margin-top: -5px">{{ $t("public.homePage.coolPlacesTitle") }}</div>
                    <p>{{ $t("public.homePage.coolPlacesText") }}</p>
                    <p class="subtitle">{{ $t("public.homePage.coolPlacesSubTitle") }}</p>
                </div>
              </slide>
                <slide class="pros-slide">
                <div class="pros-div">
                    <img class="position-absolute icon" src="../assets/svg/homepage-pros-3.svg">
                    <div class="slogan" style="margin-top: 5px">{{ $t("public.homePage.comfortTitle") }}</div>
                    <p>{{ $t("public.homePage.comfortText") }}</p>
                    <p class="subtitle">{{ $t("public.homePage.comfortSubTitle") }}</p>
                </div>

                </slide>

                <pagination slot="hooper-addons"></pagination>
              </hooper>
            </div>
            <div id="pros-wrapper">
              <div class="pros-div">
                  <img class="position-absolute icon" src="../assets/svg/homepage-pros-1.svg">
                  <div class="slogan" style="margin-top: 15px">{{ $t("public.homePage.experiencesTitle") }}</div>
                  <p>{{ $t("public.homePage.experiencesText") }}</p>
                  <p class="subtitle">{{ $t("public.homePage.experiencesSubTitle") }}</p>
              </div>
              <div class="pros-div">
                  <img class="position-absolute icon" src="../assets/svg/homepage-pros-2.svg">
                  <div class="slogan" style="margin-top: -5px">{{ $t("public.homePage.coolPlacesTitle") }}</div>
                  <p>{{ $t("public.homePage.coolPlacesText") }}</p>
                  <p class="subtitle">{{ $t("public.homePage.coolPlacesSubTitle") }}</p>
              </div>
              <div class="pros-div">
                  <img class="position-absolute icon" src="../assets/svg/homepage-pros-3.svg">
                  <div class="slogan" style="margin-top: 5px">{{ $t("public.homePage.comfortTitle") }}</div>
                  <p>{{ $t("public.homePage.comfortText") }}</p>
                  <p class="subtitle">{{ $t("public.homePage.comfortSubTitle") }}</p>
              </div>
            </div>

            <div class="next-section">
                <h2>{{ $t("public.homePage.interestsAboutTravel") }}</h2>
            </div>

            <div id='interests' class='row justify-content-center'>
                <div id="interests-wrapper">
                  <div class='interests-item' @click="searchPageRedirect('themes', [])">
                      <div class='interests-item-image interests-item-image-selected'></div>
                      <span>{{ $t("general.all") }}</span>
                  </div>
                  <div class='interests-line'></div>
                  <div class='interests-item' @click="searchPageRedirect('themes', ['CULTURE'])">
                    <div class='interests-item-image culture'></div>
                    <span>{{ $t("general.culture") }}</span>
                  </div>
                    <div class='interests-line'></div>
                  <div class='interests-item' @click="searchPageRedirect('themes', ['SPORT'])">
                    <div class='interests-item-image sport'></div>
                    <span>{{ $t("general.adventure") }}</span>
                  </div>
                    <div class='interests-line'></div>
                  <div class='interests-item' @click="searchPageRedirect('themes', ['FOOD'])">
                    <div class='interests-item-image food'></div>
                    <span>{{ $t("general.food") }}</span>
                  </div>
                    <div class='interests-line'></div>
                  <div class='interests-item'  @click="searchPageRedirect('themes', ['TOURISM'])">
                    <div class='interests-item-image tourism'></div>
                    <span>{{ $t("general.nature") }}</span>
                  </div>
                </div>
              </div>
              <TripFilter class="mb-5" :filterButtonAlignment="'center'"/>
          </div>

            <section id="favorite-travel-tips-wrapper" class="campaign-carousel-wrapper position-relative">
              <div class="container">
                <CampaignCarousel :campaign="{title: $t('general.popularTrips'), travelTips: travelTipsFavorite}"/>
              </div>
            </section>


          <div class="campaign-container" v-for="campaign in campaigns" :key="campaign.id">
            <div class="campaign-carousel-main-div position-relative">
                <div class="campaign-carousel-wrapper container" v-if="campaign.travelTips.length !== 0">
                    <CampaignCarousel :campaign='campaign'/>
                </div>
            </div>
          </div>
        </div>
        <div class="page-loading-container">
          <div v-if="isLoadingCampaigns" class="loader-1 center paging-loading --gray"><span></span></div>
        </div>
        <Footer/>
        <div class="to-top-arrow" @click="scrollToTop"></div>
    </div>


</template>

<script>

    import {mapState, mapGetters} from "vuex";
    import {CLEVERTRIP_IMG_URL}  from '@/definitions';
    import {CLEVERTRIP_API_URL} from "@/definitions";
    import Footer from "@/components/Footer";
    import CampaignCarousel from "@/components/redesign/CampaignCarousel";
    import TripFilter from "@/components/TripFilter";
    import axios from 'axios';
    import {Hooper, Slide, Pagination, Navigation as HooperNavigation} from "hooper";
    import { assignCampaignLocalizationProperties, assignTravelTipLocalizationProperties } from "@/utils/LocalizationDataConverter";
    import {CAMPAIGNS} from "@/api/graphql/query/CampaignQuery";
    import {TRAVEL_TIPS_FAVORITE} from "@/api/graphql/query/TravelTipQuery";

    const CAMPAIGNS_SINGLE_PAGE_SIZE = 8

    export default {
        name: "CleverTrip",
        components: {
            Footer,
            CampaignCarousel,
            TripFilter,
            Hooper,
            Slide,
            Pagination,
        },

        data: function() {
            return {
                throttleTimer: false,
                generalImgUrl: CLEVERTRIP_IMG_URL,
                imgUrl: null,
                isHovering: false,
                bgHover: false,
                hooperSettings: {
                  itemsToShow: 1,
                  infiniteScroll: false,
                  trimWhiteSpace: true
                },
                currentPage: 0,
                isMoreCampaignsToFetch: true,
                isLoadingCampaigns: true,
            }
        },

        computed: {
            ...mapState([
                'currentCampaign',
                'currentUser',
                'currentUserRole',
                'preferredLanguage'
            ]),
        },

        async created() {
          const randomNumber = Math.floor(Math.random() * 7) + 1
          this.imgUrl = require(`../assets/bg-picture-${randomNumber}.jpg`)
          
          // axios.get(CLEVERTRIP_API_URL+`/randomTitleImage`)
          // .then(resp => {
          //   if (resp.data.image) {
          //     const imgFileName = resp.data.image
          //     const imageUrl = this.generalImgUrl + imgFileName.slice(0,imgFileName.lastIndexOf('.')) + '-dimensions-1600pxX1200px' + imgFileName.slice(imgFileName.lastIndexOf('.'))
          //     fetch(imageUrl)
          //       .then(response => {
          //         if (response.ok) this.imgUrl = imageUrl
          //         else this.imgUrl = require('../assets/bg-image.png')
          //       })
          //       .catch(error => {
          //         console.error('Error:', error);
          //       });
          //   } else {
          //     this.imgUrl = require('../assets/bg-image.png')
          //   }
          // });
        },
        
        mounted() {
          window.addEventListener('scroll', this.handleScroll)

        },
        destroyed() {
          window.removeEventListener('scroll', this.handleScroll)
        },

        watch: {
          preferredLanguage() {
            this.currentPage = 0
            this.isLoadingCampaigns = true
            this.isMoreCampaignsToFetch = true
          }
        },

        methods: {
            handleScroll() {
              this.showButtonToTop()

              if (!this.isMoreCampaignsToFetch || this.isLoadingCampaigns) return
              const scrollThreshold = 900;
              
              if (window.innerHeight + window.scrollY >= document.body.offsetHeight - scrollThreshold) {
                this.fetchMoreCampaigns();
              }
            },
            fetchMoreCampaigns() {
              this.currentPage += 1
              this.isLoadingCampaigns = true
              this.$apollo.queries.campaigns.fetchMore({
                variables: {
                  pageSize: {
                    page: this.currentPage,
                    size: CAMPAIGNS_SINGLE_PAGE_SIZE,
                    sortAttribute: "LENGTH_OF_STAY",
                    sortDirection: "ASC"
                  },
                },

                updateQuery: (previousResult, { fetchMoreResult }) => {
                  if (fetchMoreResult.campaigns.length < CAMPAIGNS_SINGLE_PAGE_SIZE) {
                    this.isMoreCampaignsToFetch = false
                    this.isLoadingCampaigns = false
                  }

                  return {
                    campaigns: [
                      ...previousResult.campaigns,
                      ...fetchMoreResult.campaigns,
                    ],
                  }
                },
              })
            },
            showButtonToTop() {
              if (this.throttleTimer) return;
                this.throttleTimer = true;
                setTimeout(() => {
                    const scrollButton = document.querySelector('.to-top-arrow')
                    if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
                      scrollButton.classList.add("show");
                    } else {
                      scrollButton.classList.remove('show');
                    }
                    this.throttleTimer = false;
                  }, 100);
            },
            scrollToTop() {
              window.scrollTo({top: 0, behavior: "smooth"})
            },
            throttle (event, time) {
              if (this.throttleTimer) return;
                this.throttleTimer = true;
                setTimeout(() => {
                    this.cursor(event);
                    this.throttleTimer = false;
                }, time);
            },
            cursor(event) {
              let mouseCursor = document.querySelector(".cursor")
              if (!mouseCursor) return
              mouseCursor.style.top = event.pageY + "px"
              mouseCursor.style.left = event.pageX + "px"
            },
            scrollToFavoriteTravelTips() {
              let element = document.querySelector("#favorite-travel-tips-wrapper");
              element.scrollIntoView({ behavior: 'smooth', block: 'center'});
            },

            searchPageRedirect(key, value) {
              this.$store.commit('setDefaultFilter');
              let filter = this.$store.state.currentFilter;
              filter[key] = value;
              this.$store.commit('setCurrentFilter', filter);
              this.$router.push({
                name: 'searchResult',
                params: {previous: 'HomePage'}
              });
            }
        },

        apollo: {
          travelTipsFavorite: {
            query: TRAVEL_TIPS_FAVORITE,
            update({travelTipsFavorite}) {
              return travelTipsFavorite.map(travelTip => assignTravelTipLocalizationProperties(travelTip))
            },
            fetchPolicy: 'no-cache'
          },
          campaigns: {
            query: CAMPAIGNS,
            variables: {
                pageSize: {
                    page: 0,
                    size: CAMPAIGNS_SINGLE_PAGE_SIZE,
                    sortAttribute: "LENGTH_OF_STAY",
                    sortDirection: "ASC"
                },
            },
            update(data) {
              return data.campaigns.map(campaign => assignCampaignLocalizationProperties(campaign)).filter(campaign => campaign.travelTips.length > 0)
            },
            result({data}) {
              if (data.campaigns.length < CAMPAIGNS_SINGLE_PAGE_SIZE) {
                  this.isMoreCampaignsToFetch = false
              }
              this.isLoadingCampaigns = false
            }
          },
        }
    }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    .to-top-arrow {
      &.show {
        bottom: 30px;
      }
      @media screen and (max-width: 576px) {
        right: 20px;
        height: 50px;
        width: 50px;

        &.show {
          bottom: 20px
        }
      }
    }

    .page-loading-container {
      height: 60px;
      margin-bottom: 30px;
    }

    .cursor {
      height: 100%;
      width: 100%;
      outline: 100px solid $tr-green;
      position: absolute;
      z-index: 10;
      transform: translate(-50%, -50%);
      pointer-events: none;
      animation: none !important;
      margin: 0 !important;
      transition: 0.2s;
      transition-property: top, left;
    }

    .filter-container {
      overflow: visible;
      position: relative;
      z-index: 1;
    }

    @media (min-width: 1470px){
      .container {
        max-width: 1440px;
      }
    }

    .campaign-container {
      margin-top: 150px;

      h5, p {
        padding-inline: 40px;
        line-height: 25px;
      }
      h5 {
        font-size: 25px;
      }
      p {
        margin-block: 10px 30px;
        max-width: 580px;
      }
    }
    .campaign-carousel-wrapper {
        text-align: left;
        display: flex;
        margin-inline: auto;
    }

    #main-jumbo {
        background-repeat: no-repeat;
        background-color: transparent;
        margin-top: 130px;
        padding-top: 0;
        padding-bottom: 0;
        position: relative;
    }

    #main-jumbo h1 {
        font-weight: 900;
        font-size: 85px;
        line-height: 0.9;
        margin-bottom: 4rem;
        position: relative;
        z-index: 1;
        text-shadow: 3px 3px $tr-green;
        pointer-events: none;
    }

    .pros-div {
        position: relative;

        .subtitle {
          display: block;
          margin-top: 0;
          color: $tr-gray;
        }

        p {
          text-align: left;
          vertical-align: middle;
          display: block;
          margin-left: 5px;
          margin-right: 5px;
          margin-top: 170px;
          margin-bottom: 5px;
        }

        .icon {
          left: 50%;
          transform: translateX(-50%);
        }

        .slogan {
          color: $tr-white;
          position: absolute;
          top: 50px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 25px;
        }
    }

    .interests-item-image {
        position: absolute;
        cursor: pointer;
        width: 100px;
        height: 55px;
        background-image: url("../assets/svg/homepage-interest-grey.svg");

        &.culture:hover {
          background-image: url("../assets/svg/homepage-interest-orange.svg");
        }
        &.sport:hover {
          background-image: url("../assets/svg/homepage-interest-blue.svg");
        }
        &.food:hover {
          background-image: url("../assets/svg/homepage-interest-pink.svg");
        }
        &.tourism:hover {
          background-image: url("../assets/svg/homepage-interest-yellow.svg");
        }
        &.interests-item-image-selected {
          background-image: url("../assets/svg/homepage-interest-green.svg");
        }
    }


    #interests {
      margin-block: 30px;

      span {
          position: absolute;
          width: 100%;
          left: 0;
          pointer-events: none;
      }
    } 

    .interests-item {
        position: relative;
        width: 100px;
        height: 55px;
        text-align: center;
        line-height: 55px;
    }

    .interests-line {
        background-color: $tr-gray;
        height: 1px;
        margin-top: 24px;
        width: 50px;
    }

    .enter-button {
        z-index: 1;
        position: relative;
        max-width: 260px;
    }

    #header-container {
        position: absolute;
        width: 100%;
        height: 800px;
        left: 0px;
        top: 0px;
    }

    #main-container {
        overflow: hidden;
        color: $tr-black;
        margin-top: 700px;
        padding-bottom: 100px;
    }

    .glasses-block{
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 800px;
      overflow: hidden;
      background-color: $tr-green;
      background-image: url("../assets/bg-picture-1.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .glasses-block .absolute-animated{
      position: absolute;
      width: 400vw;
      height: auto;
      top: -18vw;
      left: -16vw;
      animation-name: glasses-smallest;
      animation-duration: 30s;
      animation-iteration-count: infinite;

      @media (max-width: 420px) {
        margin-left: -20vw;
      }


      @media (min-width: 420px) {
        left: -51vw;
        animation-name: glasses-small;
        width: calc(500px + 250vw);
        margin-left: -50vw;
      }
      
      @media (min-width: 500px) {
        margin-left: -37vw;
      }

      @media (max-width: 768px) {
        margin-top: -20vw;
      } 
      @media (min-width: 768px) {
        margin-left: 0;
        top: -20vw;
        left: -51vw;
        width: 250vw;
        height: auto;
        animation-name: glasses-tall;
      }
      @media (min-width: 1200px) {
        top: -20vw;
        left: -51vw;
        animation-name: glasses;
        width: 250vw;

      }

      @media (min-width: 1650px) {
        top: -30vw;
        left: -60vw;
        width: 210vw;
        animation-name: glasses-xl;
        margin-top: 0;
      }
    }

    @keyframes glasses-smallest {
      0%   {left:-120vw; top:-57vw;}
      20%  {left:-126vw; top:-80vw;}
      40%  {left:-132vw; top:-110vw;}
      60%  {left:-137vw; top:-85vw;}
      80%  {left:-108vw; top:-74vw;}
      100% {left:-120vw; top:-57vw;}
    }
    @keyframes glasses-small {
      0%   {left:-75vw; top:-54vw;}
      20%  {left:-81vw; top:-70vw;}
      40%  {left:-85vw; top:-90vw;}
      60%  {left:-90vw; top:-80vw;}
      80%  {left:-67vw; top:-66vw;}
      100% {left:-75vw; top:-54vw;}
    }

    @keyframes glasses-tall {
      0%   {left:-68vw; top:-51vw;}
      20%  {left:-75vw; top:-70vw;}
      40%  {left:-82vw; top:-79vw;}
      60%  {left:-86vw; top:-67vw;}
      80%  {left:-60vw; top:-61vw;}
      100% {left:-68vw; top:-51vw;}
    }

    @keyframes glasses {
      0%   {left:-65vw; top:-68vw;}
      20%  {left:-75vw; top:-79vw;}
      40%  {left:-79vw; top:-87vw;}
      60%  {left:-87vw; top:-78vw;}
      80%  {left:-56vw; top:-72vw;}
      100% {left:-65vw; top:-68vw;}
    }

    @keyframes glasses-xl {
      0%   {left:-50vw; top:-60vw;}
      20%  {left:-56vw; top:-68vw;}
      40%  {left:-61vw; top:-74vw;}
      60%  {left:-66vw; top:-67vw;}
      80%  {left:-40vw; top:-63vw;}
      100% {left:-50vw; top:-60vw;}
    }

    @media screen and (max-width: 1200px) {

        .row {
            display: block;
        }
    }

    @media screen and (max-width: 768px) {

        #main-container {
          margin-top: 520px;
        }

        #main-jumbo h1 {
            font-size: 35px;
            margin-bottom: 10rem;
            text-shadow: 1.5px 1.5px $tr-green;
        }

        #pros-wrapper div {
            margin-left: 0;
            margin-right: 0;
        }

        #interests-wrapper {
            margin: 0px auto;
            width: 100px;
        }

        .interests-line {
            display: none;
        }

        .glasses-block {
          height: 600px;
        }

    }

    #interests-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;
      gap: 30px;
    }

    @media screen and (max-width: 768px) {
      #pros-wrapper-carousel {display: block !important;}
      #pros-wrapper {display: none !important;}
    }

    #pros-wrapper-carousel {
      display: none;
      margin-top: 100px;
      margin-bottom: 150px;

      &+.next-section{
        margin-top: 50px;
        position: relative;
        display: block;
      }
    }
    .pros-hooper {
      height: 100%;

      .pros-div {
        max-width: 320px;
        margin-inline: auto !important;

        p {
          margin: 0;
          padding-top: 170px;
        }
        .subtitle {
          padding-top: 0;
        }
      }
    }

    #pros-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 100px;

      .pros-div{
        width: 320px;
        padding-bottom: 50px;
      }

      & + .next-section{
        margin-top: 50px;
        
        h2{
          font-size: 2.5rem;
        }
      }
    }
</style>

<style lang="scss">
    @import '@/scss/variables';

  .hooper-pagination {
    margin-top: 20px;
    position: static;
    transform: none;

    .hooper-indicators {
      margin: 0 auto;
    }
    .hooper-indicator {
      margin: 0 10px;
      width: 50px;
      height: 4px;
      border-radius: 0px;
      border-top: 0px solid transparent !important;
      border-bottom: 0px solid transparent !important;
      border-left: 0px solid transparent;
      border-right: 0px solid transparent;
      padding: 0;
      background-color: $tr-white;
      cursor: pointer;
    }
  
    @media (max-width: 767px) {
      .hooper-indicator {
        width: 50px !important;
      }
    }
    
    .hooper-indicator {
      background-color: $tr-gray;
    }
    
    .hooper-indicator.is-active {
      background-color: $tr-green;
      height: 6px;
    }
  }
  
  </style>
