<template>
    <div v-if="this.campaign && publishedTips.length > 0" class="carousel">
      <div>
        <h5>{{this.campaign.title}}</h5>
        <p>{{this.campaign.description}}</p>
      </div>

        <!-- https://github.com/baianat/hooper -->
        <hooper :settings="hooperSettings" :wheel-control="false" :transition="500" :autoPlay="false" :playSpeed="8000" :itemsToShow="5.15" :centerMode="false" pagination="no" :infiniteScroll="false" :perPage=2 :speed=300 :loop="true" :minSwipeDistance=10 :scrollPerPage="false" :paginationEnabled="false">
            <slide v-for="travelTip in publishedTips" :key="travelTip.id">
                <router-link type='button' class='travel-tip' :to="{name:'travelTipDetail', params:{travelTipId:travelTip.id}}">
                    <TripItem :trip='travelTip' :campaign='campaign'/>
                </router-link>
            </slide>

            <hooper-navigation slot="hooper-addons" v-if="publishedTips.length > getNumberOfItems"></hooper-navigation>
        </hooper>
    </div>
</template>

<script>

    import TripItem from "@/components/redesign/TripItem";
    import {Hooper, Slide, Navigation as HooperNavigation} from "hooper";
    import 'hooper/dist/hooper.css';

    export default {
        name: "CampaignCarousel",

        props: {
            campaign: null,
        },

        components: {
            TripItem,
            Hooper,
            Slide,
            HooperNavigation,
        },

        data() {
            return {
              width: window.innerWidth,
              height: document.documentElement.clientHeight,
                
                hooperSettings: {
                    itemsToShow: 1,
                    breakpoints: {
                        600: {
                          itemsToShow: 2
                        },
                        900: {
                          itemsToShow: 3
                        },
                        1200: {
                          itemsToShow: 4
                        },
                    },
                    infiniteScroll: false,
                    trimWhiteSpace: true
                }
            }
        },

        methods: {
          getDimensions() {
            this.width = window.innerWidth;
            this.height = document.documentElement.clientHeight;
          },
          searchPageRedirect(key, value) {
            this.$store.commit('setDefaultFilter');
            let filter = this.$store.state.currentFilter;
            if (key != 'default') {
              filter[key] = [value];
            }
            this.$store.commit('setCurrentFilter', filter);
            this.$router.push({
              name: 'searchResult',
              params: {previous: 'HomePage'}
            });
          }
        },

        computed: {
          publishedTips() {
            if (!this.campaign.travelTips) return []
            return this.campaign.travelTips.filter(tip => tip.state == "PUBLISHED").sort((a, b) => new Date(b.topDate) - new Date(a.topDate))
          },

          getNumberOfItems() {
            if (this.width >= 1200) {return 4}
            else if (this.width >= 900) {return 3}
            else if (this.width >= 600) {return 2}
            else {return 1}
          }
        },

        mounted() {
          window.addEventListener('resize', this.getDimensions);
        },
        unmounted() {
          window.removeEventListener('resize', this.getDimensions);
        },
    }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    .travel-tip {
      text-decoration: none;
      width: 100%;
      padding: 15px 10px;
    }

    h5 {
        line-height: 25px;
        margin-bottom: 10px;
        font-size: 25px;
    }
    p {
      line-height: 25px;
      max-width: 580px;
      margin-bottom: 30px;
    }

    .carousel {
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }

    .hooper {
        height: auto;
        overflow: visible;
    }


    #carousel-indicator {
        margin-bottom: -40px;
    }

</style>

<style lang="scss">
    @import '@/scss/variables';

  .campaign-container .hooper-list {
    padding-block: 8px;
  }

    @media screen and (max-width: 1800px) {
      .campaign-carousel-wrapper .carousel {
        &, & .hooper {
          position: static !important;
        }


        .hooper-navigation {
          .hooper-prev, .hooper-next {
            width: 130px;
            height: 130px;
          }
          .hooper-prev {
            left: 0 !important;
            background-image: url("data:image/svg+xml,%3Csvg width='65' height='130' viewBox='0 0 65 130' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cy='65' r='65' fill='%23222222'/%3E%3Cpath d='M34.9375 75.5625L24.375 65L34.9375 54.4375' stroke='white' stroke-width='3'/%3E%3C/svg%3E%0A");
          }
          .hooper-next {
            right: -65px !important;
            background-image: url("data:image/svg+xml,%3Csvg width='65' height='130' viewBox='0 0 65 130' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='65' cy='65' r='65' transform='rotate(-180 65 65)' fill='%23222222'/%3E%3Cpath d='M31.0625 54.4375L41.625 65L31.0625 75.5625' stroke='white' stroke-width='3'/%3E%3C/svg%3E%0A");
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      .campaign-carousel-wrapper .carousel .hooper .hooper-navigation {
          .hooper-prev, .hooper-next {
            width: 90px;
            height: 90px;
          }
          .hooper-prev {
            left: 0 !important;
          }
          .hooper-next {
            right: -45px !important;
          }
        }
    }

    .hooper-indicator {
        margin: 0 10px;
        width: 50px;
        height: 5px;
        border-radius: 0px;
        border-top: 2px solid $tr-white;
        border-bottom: 2px solid $tr-white;
        border-left: 0px solid transparent;
        border-right: 0px solid transparent;
        padding: 0;
        background-color: $tr-white;
        cursor: pointer;
    }

    @media (max-width: 767px) {
      .hooper-indicator {
        width: 7px;
      }
    }

    .hooper-indicator {
        background-color: $tr-gray;
    }

    .hooper-indicator.is-active {
        background-color: $tr-green;
        height: 7px;
    }

</style>
